

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family: "Open Sans", sans-serif;
 

}
*{margin:0px;padding: 0px;}

header {width: 100%;height: 80px;background: white; position: fixed;top: 0;left:0;z-index: 1000;}

body.dark header{background:#112143;}

header .logo{text-decoration: none;}
header .logo img{width: 45px;}
header .row {margin: 0px !important;}





header button.rounded-circle{min-width: 40px !important; width: 40px !important; height: 40px !important; background: #f0f5ff !important; color: #0858f7;} 
header button.rounded-circle svg{color: #292929; font-size: 18px !important; transition: all 0.2s ease-in-out;}

header button.rounded-circle:hover svg{color: #0858f7 !important;}

body.dark header button.rounded-circle{background:#112755 !important ;}
body.dark header button.rounded-circle svg{color: #fff !important;}







header .part2{padding-left: 50px;}

/* General styles for larger screens */
.serchbox {
  width: 400px;
  height: 40px;
  background: #f0f5ff;
  border: none;
  border-radius: 6px;
}

/* Hide the .serchbox class when screen width is 768px or less */





.dashbordBoxwraper {
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}



body.dark .serchbox{
  background:#12254e !important;
  color: white;

}

body.dark .serchbox input {
color: #fff;
}

body.dark .serchbox input::-webkit-input-placeholder{color: #fff;}

.serchbox input {
  background: transparent;
  border: 0px;
  outline: none;
  padding-left: 5px;
  font-size: 15px;
}

.myacc {
  text-align: left !important;
  color: black !important;
  text-transform: capitalize !important;
}

.myacc .userImg .rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.myacc .userImg .rounded-circle img {
  object-fit: contain;
  width: 100%;
}

.myacc .userinfo {
padding-left: 10px;
}

body.dark .myacc .userinfo {
  color: rgba(255, 255, 255, 0.8) !important  ;
  }

  body.dark .myacc .userinfo .useremail{
    color: rgba(255, 255, 255, 0.8) !important  ;
    }
  

  

  body.dark .MuiPaper-elevation {
    background: #12254e;
  }

  body.dark .MuiPaper-elevation li *, body.dark .MuiPaper-elevation li {
   color: rgba(255, 255, 255, 0.8) !important;
  }

.myacc .userinfo h4{
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.myacc .userinfo {
  padding-left: 15px;
}

.myacc .userinfo p{
margin-top: 0px;
font-size: 12px;
color: #000;
opacity: 0.7;
}

.main .sidebarwrapper {
  flex: 0 0 20%;
  transition: all .3s;
  width: 20%;
}

.main .sidebarwrapper.toggel{
  width:0%;
  flex: 0 0 0%;
}

.main .sidebarwrapper .sidebar{
  width: 20%; height: 100vh; max-height:calc(100% - 70px); position: fixed; top: 70px; left: 0px; background: #fff; overflow-y: scroll; overflow-x: hidden; padding-bottom: 40px; transition: all 0.3s;
}


body.dark .main .sidebarwrapper .sidebar {
  background:#112143;
}


.main .sidebarwrapper.toggel .sidebar{

  left: -100%;
  
  }


/* Base scrollbar style for .sidebar */
.main .sidebarwrapper .sidebar::-webkit-scrollbar {
  width: 3px !important;
}

/* Scrollbar thumb style for .sidebar */
.main .sidebarwrapper .sidebar::-webkit-scrollbar-thumb {
  background: transparent !important;
}

/* Scrollbar and thumb style on hover of .sidebarwrapper */
.main .sidebarwrapper:hover .sidebar::-webkit-scrollbar-thumb {
  background: #0858f7 !important;
}

.main .sidebarwrapper:hover .sidebar::-webkit-scrollbar {
  background: #0858f7 !important;
}

body.dark .main .sidebarwrapper:hover .sidebar::-webkit-scrollbar {
  background:rgb(136, 134, 134) !important;
}

body.dark .ant-tabs {
  color: rgb(203, 202, 202) !important;
}

.main .sidebarwrapper .sidebar ul {margin-bottom: 0px; padding: 10px 15px;}

.main .sidebarwrapper .sidebar ul li {list-style: none;}

.main .sidebarwrapper .sidebar ul li button {
  color: #000;
  text-align: left;
  justify-content: left;
  border-radius: 5px !important;
  padding: 12px 10px !important ;
  text-transform: capitalize !important;
  align-items: center;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;

}

body.dark .main .sidebarwrapper .sidebar ul li button {
  color: rgba(255, 255, 255, 0.8) !important;
}

.main .sidebarwrapper .sidebar ul li button *{color: 0,0,0,0.7 !important;}

.main .sidebarwrapper .sidebar ul li button .icon{

  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}


.main .sidebarwrapper .sidebar ul li button .icon svg {
  color: #000;
  opacity: 0.8;
  font-size: 22px;
}

body.dark .main .sidebarwrapper .sidebar ul li button .icon svg {
color:rgba(255, 255, 255, 0.8) !important;
}

.main .sidebarwrapper .sidebar ul li button .arrow{
  margin-left: auto ;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  transition: all 0.3s ease-in-out;

}

.main .sidebarwrapper .sidebar ul li button :hover {
  background: #f1f1f1 !important;
}


body.dark .main .sidebarwrapper .sidebar ul li button :hover {
  background:rgba(255, 255, 255, 0.1) !important;
}

.main .sidebarwrapper .sidebar ul li button .active {
  background: #f1f1f1 !important;
}


body.dark .main .sidebarwrapper .sidebar ul li button .active {
  background:rgba(255, 255, 255, 0.1) !important;
}

button.active .icon svg {
  color: #0858f7 !important;
}



.active .arrow {
  transform: rotate(90deg);
 }


.active .arrow {
 transform: rotate(90deg);
}

.main .sidebarwrapper .sidebar ul .submenu {
  padding: 0px 45px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.main .sidebarwrapper .sidebar ul .submenu ::before {
  content: '';
  width: 1px;
  height: 95%;
  position: absolute;
  top: 0px;
  left: 25px;
  background: rgba(225, 224, 224, 0.6);
}


.main .sidebarwrapper .sidebar ul .submenu li {
  width: 100% !important;
  list-style:none  !important;
  text-align: left;

}

.main .sidebarwrapper .sidebar ul .submenu li a{
  color:#5e5d72 !important;
  text-decoration: none;
  display: block;
  padding: 6px 0px !important;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
}


body.dark .main .sidebarwrapper .sidebar ul .submenu li a {
  color: rgba(255, 255, 255, 0.8) !important;

}

.main .sidebarwrapper .sidebar ul .submenu li a:hover {
  color: #0858f7 !important;

}

.submenuwarper {
  overflow: hidden; /* Prevent overflow */
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.colapsed {
  height: 0;
  opacity: 0;
}

.colapse {
  height: auto;
  opacity: 1;
  overflow: inherit;
  padding: 10px 0px;
}

.logoutwraper{
  padding: 0px 25px;
 
}

.logoutwraper .logoutbox {
  width: 100%; height: 130px; padding: 25px; background: #bcd2fd; border-radius: 10px;  display: flex; align-items: center; justify-content: center; position: relative; overflow: hidden;
}

body.dark .logoutwraper .logoutbox {
  background: #042e82;

}

body.dark {
  background: #071739 !important;

}

body.dark .card {
  background: #112143 !important;
  color: white !important;
}

body.dark .ant-modal .ant-modal-content {
  background: #e5ebf8 !important;
}

body.dark .ant-modal-header .ant-modal-title {
  background: #e5ebf8 !important;
}


body.dark .ant-pagination .ant-pagination-item-active {
  background-color:#112143 !important;
  color: white;
}


body.dark .ant-pagination .ant-pagination-next .ant-pagination-item-link{
  color: white
}

body.dark .ant-pagination .ant-pagination-previous .ant-pagination-item-link{
  color: white
}


body.dark .card-body {
  background: #112143 !important;
}

.logoutwraper .logoutbox ::before {
  content: '';
  width: 50px;
  height: 50px;
  background: #0858f7;
  border-radius: 100%;
  position: absolute;
  top: -60px;
  right: -75px;
  opacity: 0.5;
  }

  .logoutwraper .logoutbox ::after {
    content: '';
    width: 30px;
    height:30px;
    background: #0858f7;
    border-radius: 100%;
    position: absolute;
    top: 16px;
    left: -60px;
    opacity: 0.5;
    }

.logoutwraper .logoutbox button {
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.logoutwraper .logoutbox button  svg{
margin-right: 10px;
font-size: 18px !important;
font-weight: 600 !important;
}



.main .content{    flex: 0 0 80%;
    transition: all .3s;
    width: 80%;}

.main .content.toggel{width: 100%; flex: 0 0 100%;}
.right-content {padding: 90px 30px 10px}
/* .right-content{padding: 90px 30px 10px;margin-top: 90px} */

.dashbordBoxwraper{gap:15px;width: 100%;flex-wrap: wrap;}

.dashbordBoxwraper .dashbordbox {
  width: 49%;
  height: 170px;
  border-radius: 10px ;
  overflow: hidden;
  padding: 20px;
}

.dashboardBoxWrapperRow .box{width: 100%; height: 100%; }

.dashbordBoxwraperrow .dashbordboxtow {
  width: 100%;
  height: 100%;

}


.dashbordbox .col1 h4 {font-size: 20px; font-weight: 600;}
.dashbordbox .col1 span {font-size: 40px; font-weight: bold;line-height: 45px;}

.icon-container svg{font-size: 40px; opacity: 0.1; color: #fff;  }

.dashbordbox {
  position: relative; /* Position the container relative to its normal position */
}

.icon-container {
  position: absolute; /* Position the icon-container absolutely within the dashbordbox */
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */

}

.icon-container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-image: linear-gradient(to right, rgba(0, 0,0, 0.1) ,rgba(0,0,0,0.2));
}

.toggleicon {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.continerbackground {

  font-weight: 800;
  font-size: 170px;
  position: relative;
  bottom: 140px;
  left: 70px;
  opacity: 0.1;
  color: black;

}

.continerbackground svg {
  height: 120px;
  
}


.dashbordboxtow {
  background-image: linear-gradient(#1a50b5, #326de3);
  border-radius: 10px;
  padding: 25px;
  position: 'relative'
}



.buttononoff {

  font-weight: 800;
  font-size: 150px;
  position: relative;
  bottom: 140px;
  left: 70px;
  opacity: 0.1;
  color: black;

}

.onoffbutton button {
  background: transparent;
  border: none;
}

.onoffbutton button :hover {
  background: transparent;
  border: none;
}


.workingpratner-content {padding: 19px 30px ; margin: 5px;}


.card-body {
  background-color: white !important;
}


body.dark .nav-pills .nav-link {
  color: white !important;
 
}


.remaindayschart {
  margin-left: 25%;
  margin-right: 25%;
}


.onoffbutton button svg {
  color: #0858f7 !important;
} 


body.dark .card h5 {
  font-size: 1.2rem; /* Slightly larger headings */
  font-weight: bold; /* Bold headings */
  color: white; /* Darker color for contrast */
  margin-bottom: 10px; /* Space between elements */
}


.mobiletoogel{
  display: none;
  visibility: hidden;
}


.main .sidebarwrapper .sidebar ul li button.active {
  background: #f1f1f1 !important;
}

body.dark .main .sidebarwrapper .sidebar ul li button.active {
  background: #ffffff1a!important;
}

body.dark .stghead {
  color: white !important;
}

body.dark .stgnote {
  color: #B0B0B0 !important;
}

body.dark .card-body label span:before {
  content: '';
  position: absolute;
  top: 0;
  width: 65%;
  height: 50%;
  background: rgba(255, 255, 255, .1);
}


body.dark .billdetial{
  background: #112143 !important;
  color: white !important;
}

body.dark .MuiTypography-h4 {
  color: #FFFFFF ;
}

body.dark .MuiTypography-h5 {
  color: #E0E0E0  ;
}

body.dark .MuiTypography-h6 {
  
  color: #F39C12  ;
}

body.dark .MuiTypography-body1 {
  
  color: #A0A0A0  ;
}


body.dark .MuiTypography-body2 {
  
  color: #32CD32  ;
}


body.dark .ant-pagination-item{
  color: white;
}



body.dark .css-1g94m0c-MuiPaper-root-MuiPopover-paper-MuiMenu-paper::before {

  background: #112143 !important;

}

@media only screen and (min-width:320px) and (max-width:992px){
  .mobiletoogel{
    display: contents;
    visibility: unset;
    }

    

    .continerbackground svg {
      height: 100px;
      margin-bottom: 20px;
      margin-left: 30px;
      
    }

  #notifications .MuiPaper-elevation{right: 240px !important; width: 300px !important; left: auto !important; top: 70px !important;}

  .indexcard {
    margin-bottom: 15px;
  }

  .continerbackground {
    font-weight: 800;
    font-size: 150px;
    position: relative;
    bottom: 110px;
    left: 20px;
    opacity: 0.1;
    color: black;
  }


  .poweronoff {
    margin-left: 70px !important;
}

  .res-hide{display: none !important;}
  .res-col{flex-direction: column !important;}

  body,html{overflow-x: hidden !important;}

  header{padding: 15px 0px; height: auto;}
  header .part1,header .part3{padding: 0px !important;}
  header .part1{max-width: 40% !important; flex: 0 0 40% !important;}
  header .logo img{width: 35px !important;}
  header .logo span{font-size: 18px;}

  header .part3{max-width: 60% !important; flex: 0 0 60% !important;}


  .main .sidebarwrapper{width: 80% !important; height: 100vh !important; position: fixed; top: 0px; left: -100%; z-index: 10000; opacity: 0; transition:all 0.3s ease-in-out}
  .main .sidebarwrapper .sidebar{width: 100% !important; position: relative !important; top: 0px !important;  max-height:inherit !important;}

  .sidebarwrapper.open{left: 0px !important; opacity: 1 !important;}

.sidebarOverlay{position: fixed; top: 0px; left: 0px; background: rgba(0,0,0,0.7); z-index: 100; width: 100%; height: 100% !important; z-index: 1000;}
.sidebarOverlay.show{display: block !important;}


  .main .content{width: 100% !important; flex: 0 0 100% !important;}
  .right-content{padding: 90px 15px !important;}

  .dashbordBoxwraper{white-space: nowrap !important; overflow-x: scroll; overflow-y: hidden; display: block !important; margin-bottom: 20px !important;}
  .dashbordBoxwraper::-webkit-scrollbar{display: none !important;}
  .dashbordBoxwraper .dashbordbox{display: block !important; width:100% !important; height: 160px !important; margin-right: 10px !important; margin-top:10px;}
  .topPart2{padding-left: 15px !important;}

  .tableFooter{flex-direction: column;}
  .tableFooter nav{margin-top: 25px;}
  .tableFooter nav ul{white-space: nowrap !important; overflow-x: scroll; overflow-y: hidden; display: block !important; margin-bottom: 20px !important;}
  .tableFooter nav ul li{display: inline-block !important; width: max-content !important; height: auto !important; }

  .card.shadow.border-0.w-100.flex-row{margin-top: 0px !important;}
  .card.shadow.border-0.p-3.mt-4{margin-top: 0px !important;}

  .MuiBreadcrumbs-root{margin-top: 15px !important;}

  .productInfo .col-sm-3{max-width: 200px !important; flex: 0 0 200px !important;}
  .productInfo .col-sm-9{max-width: 100% !important; flex: 0 0 100% !important; padding-left: 36px !important;}

  .loginSection{height: auto !important;}
  .signUpSection .col-md-8{display: none !important;}

  #notifications .MuiPaper-elevation{right: 20px !important;}


  .billdetial .table tfoot th, .table tfoot td{
    font-size: 0.9rem !important;
  }
  
  .h6, h6 {
    font-size: 1rem !important;
}

.placeorderbtn {
  width: 100%; /* Ensures full width on mobile */
  max-width: 300px; /* Optional: Set a max width on larger screens */
  margin: 0 auto; /* Centers the button */
}



.box {
  height: 400px;
  width: 330px;
  margin-top: 25px;
}

.top {

  top: -20px;
  width: 330px;

}


.mid p:first-child {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.billdetial .table th {

  font-size: 0.8rem !important;

}

.billdetial .table td {

  font-size: 0.7rem !important;

}

h2.Cardheder {
  font-size: 20px;
}

.invoicehead h3{
  font-size: 15px;
}

.invoicehead h5{
  font-size: 18px;
}


.chekouttable table th {
  font-size: 5px;
}

.chekouttable tbody, td, tfoot, th, thead, tr {
  font-size: 12px;

}

.clients-dec {

  border: 1px solid black;
  margin-top: 5px;
  margin-bottom: 5px;

}

}

@media only screen and (min-width:320px) and (max-width:575px){
  header .row{margin: auto !important;}
  header button.rounded-circle{width: 35px !important; min-width: 35px !important; height: 35px !important; margin-right: 8px !important;}
  header .btn-blue{font-size: 12px !important; padding: 5px 10px !important;}
  .userImg{width: 35px; height: 35px;}

  .myAccWrapper button{padding: 0px !important; min-width: 35px !important; width: 35px !important;}


  
  
}



@media only screen and (min-width:320px) and (max-width:767px){
  .form .col{max-width: 100% !important; flex: 0 0 100% !important;}
}




 .dashboard-container {
  padding: 20px;
} 

.filters {
  margin-bottom: 20px;
} 

 .filters label {
  margin-right: 20px;
} 

.filters input {
  margin-left: 10px;
  padding: 5px;
} 

.cardTrade {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

.trade-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sub-text {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  margin-right: 5px;
}

/* Green background for "Buy" */
.buy-badge {
  background-color: green;
}

/* Red background for "Sell" */
.sell-badge {
  background-color: red;
}


.intraday-badge {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 5px;
  background: orange;
  color: #fff;
  font-weight: bold;
  margin-right: 5px;
}

.loss {
  color: red;
}

.profit {
  color: green;
}

.total-profit-loss {
  margin: 20px 0;
  font-size: 0.5rem;
  font-weight: bold;
}


@media (max-width: 600px) {
  .dashboard-container {
    padding: 10px;
  }

  .filters {
    flex-direction: column;
    gap: 8px;
  }

  .filters label {
    font-size: 13px;
  }

  .filters input {
    font-size: 12px;
    padding: 5px;
  }

  .card {
    padding: 15px;
  }

  .trade-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .sub-text {
    font-size: 12px;
  }

  .total-profit-loss {
    font-size: 13px;
    padding: 8px;
  }

  .pnadl {
    margin-top: 5px;
  }

  .group-header h4 {
    font-size: 15px;
  }

  .tradeexp h4 {
    font-size: 15px;
  }

  
  
}


















