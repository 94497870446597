@media (max-width:1300px) {
    .header_wrapper .menu-navbar-nav {
        width: auto;
    }
}


@media (max-width:1300px) {
    .header_wrapper .nav-item {
        margin: 0 5px;
    }
}


@media (max-width:1024px) {
    .header_wrapper .nav-item .nav-link {
        font-size: 16px;
    }
}

/* btn btn-primary */

/* @media (max-width:991px) {
    .header_wrapper .menu-navabr-nav {
      text-align: center ;
    }
} */


/* .header_wrapper .nvabr-collapse {
    background:var(--bg-white);
} */