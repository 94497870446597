.calendar-wrapper {
    width: 100%;
    max-width: 1200px;
    /* margin: auto; */
    text-align: center;
    background: #bcd1f5;
    padding: 20px;
    border-radius: 10px;
    
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .button {
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .day {
    font-weight: bold;
    text-align: center;
  }
  
  .cell {
    min-height: 90px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    background: #dcdbdb;
   
    
  }
  
  .cell.current-month {
    background: white;
    border: 1px solid #ddd;
  }
  
  .cell.profit {
    background: #d4edda;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  
   
  }
  
  .cell.loss {
    background: #f8d7da;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

   
  }
  
  .cell .profit-loss {
    font-size: 15px;
    margin-top: 5px;
    font-weight: bold;
    
    
  }
  
  .cell .profit-loss.green {
    color: green;
    font-size: 18px;
    
    
  }
  
  .cell .profit-loss.red {
    color: red;
    font-size: 18px;
  }

  .cell .profit-loss.black {
    color: black;
  }
  
  .buy-sell {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-left: 5px;
    
    
 
  }
  
  .buy-sell span {
    display: block;
  }



  .green {
    color: green;
    
  }
  
  .red {
    color: red;
  }
  
  .black {
    color: black;
  }
  
  

  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(7, 1fr); /* Maintain 7 columns */
      gap: 2px;
    }
  
    .cell {
      min-height: 50px; /* Reduce cell height */
      font-size: 8px; /* Reduce text size */
      padding: 3px;
    }
  
    .header {
      font-size: 16px; /* Reduce header font size */
    }
  
    .button {
      padding: 4px 8px;
      font-size: 14px;
    }
  
    .cell .profit-loss {
      font-size: 12px;
    }
  
    .cell .profit-loss.green,
    .cell .profit-loss.red {
      font-size: 8px;
    }
  
    .buy-sell {
      font-size: 8px;
    }

    .cell.profit {
      background: #d4edda;           
    }
    
    .cell.loss {
      background: #f8d7da;          
    }

    .buy-sell span {
     visibility: hidden;
     display: none;

    }

    
  }
  
