@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
  
  body {

      /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif; */
        font-family: "Open Sans", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'Poppins';
     
    }

    .app {
      background-color: black;

    }

    .page-content {
      background-color: white;
    }

    .container {
      margin: 25px;


    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }



    .App {
      text-align: center;

    }

    .App-logo {
      height: 40vmin;
      pointer-events: none;
    }

    @media (prefers-reduced-motion: no-preference) {
      .App-logo {
        animation: App-logo-spin infinite 20s linear;
      }
    }

    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;

    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    .navbar {
      background-color:  rgba(178, 217, 246, 0.3);
    }

    .navbar-color {
      background-color: rgba(178, 217, 246, 0.3);
      width: 100%;

    }

    .navbar-nav li {
      display: inline-block;
      padding: 5px;
    }

    .navbar-nav {
      background-color:rgba(231, 239, 246, 0.3);
    }

    /* new css */

    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Quicksand:wght@300;400;500;600&display=swap');

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    :root {
      /* background */
      --bg-black: #090b3c;
      --bg-white: #fff;
      --bg-light-white: #fcfcfc;
      --bg-light-2: #f2f7fa;
      --bg-dark-green: #0e9d80;
      --bg-features-1: #fff6d6;
      --bg-features-2: #ebdcf9;
      --bg-features-3: #ddfcfc;
      --bg-features-4: #dcf6e8;
      --bg-features-5: #f8e4e1;
      --bg-features-6: #fbffd4;

      /* text color */

      --text-black: #090b3c;
      --text-white: #fff;
      --text-dark-gray: #4c535d;
      --text-light-2: #798089;
      --text-light-3: #5e5e72;
      --text-light-4: #A0A0A0;
      --text-dark-green: #12c4a0;
      --text-orange: #ff5d2a;
      --text-yellow: #ffc100;

      /* fonts */

      --primary-font: Quicksand;
      --secondary-font: 'Popins', sans-serif;
      --normal-font-weight: 400;
      --bold--font-weight: 700;
    }

    body {
      font-family: var(--primary-font);
      font-weight: var(--normal-font-weight);
      background-color: var(--bg-white);

    }


    .header_wrapper .navbar {
      -webkit-transition: all .2s linear;
      transition: all .2s linear;

    }

    .header_wrapper .menu-navabr-nav {
      width: 70%;
      display: flex;
      justify-content: center;
    }

    .header_wrapper .nav-item {
      margin: 0 10px;
    }

    .header_wrapper .nav-item .nav-link {
      font-weight: var(--bold--font-weight);
      font-size: 18px;
      color: var(--text-dark-gray);
      line-height: 1;
    }

    .header_wrapper .nav-item .nav-link.active {

      color: var(--text-dark-green);

    }

    .header-scrolled {
      position: fixed;
      margin-top: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 85px;
      background: var(--bg-white);
      box-shadow: 0 4px 6px 0 rgb(12, 0, 46, .05);
    }

    .header-scrolled .navbar-brand img {
      width: 190px;
      height: auto;
      animation: alternate 0.15 ease-in-out;
    }

    @keyframes animated {

      0% {
        width: 210px;
      }

      0% {
        width: 210px;
      }

    }

    .header-scrolled .nav-item .nav-link {
      font-size: 17px;
    }

    @keyframes slideInFromRight {
      from {
          transform: translateX(100%);
          opacity: 0;
      }
      to {
          transform: translateX(0);
          opacity: 1;
      }
  }
  
  .slide-in-right {
      animation: slideInFromRight 0.5s ease-out forwards;
  }
  




    .banner_wrapper {
      display: flex;
      width: 100%;
      padding: 150px 0 125px;
      background: url(../images/simple-blue-white-background-with-text-space.jpg) no-repeat;
      background-size: cover;
      justify-content: center;
      align-items: center;
     
    }
    .overview {

      width: 100%;
      padding: 150px 0 125px;
      background: url(../images/yellow-abstract-background-dynamic-flowing-waves_24972-2042.jpg) no-repeat;
      background-size: cover;
      justify-content: center;
      align-items: center;
      
    }

  

    .banner_wrapper .banner-subtital {
      font-size: 24px;
      font-weight: 700px;
      line-height: 1.5;
      margin-bottom: 15px;
      color: var(--text-light-3);
    }

    .banner_wrapper .banner-titel {
      font-family: var(--secondary-font);
      font-weight: 800;
      font-size: 60px;
      text-transform: capitalize;
      line-height: 1.15;
      margin-bottom: 25px;
      color: var(--text-black);
    }

    .banner_wrapper .banner-titel span {
      color: var(--text-dark-green);
    }

    .banner_wrapper .banner-titel-text {
      font-size: 17px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 40px;
      max-width: 450px;
      color: var(--text-light-2);

    }

    .header-img-section {

   
    }

    .banner_wrapper .header-img-section img {
      width: 100%;
      animation: f
    }

    @keyframes flot {

      0% {
        transform: translateY(0px);
      }

      50% {
        transform: translateY(20px);
      }

      100% {
        transform: translateY(0px);
      }



    }

    .banner_wrapper .banner-titel {
      font-size: 50px;
    }

    .butoosection {
      justify-content: right;
    }

    .authcontianer-main{
      /* background-image: url("../images/Background-main.jpg"); */
      margin-top: 10px;
  
     
    }

    .editplan .ant-btn-primary {
      background-color: transparent!important;
      font-weight: bold;
      font-size: 15px;
      color: black;
    }

    .authcontianer {
      width: 100%;
      margin-top: 10  0px;
      text-align: left;
      box-shadow: 0px 0px 5px 5px #9a9898;
      padding: 25px;
      border-radius: 10px;
      border: 1px solid #9a9898;
      background-color: var(--bg-light-white);
      height: 75%;
    }

    .aut-sigin {
      margin-top: 25px;
      text-align: left;
      padding: 25px;
      border-radius: 10px;
      background-color: #c4d0de;
    }

    .container-fluid {
      background-color: red,

    }

    .signright {
      margin-top: 25px;
      text-align: left;
      padding: 25px;
      border-radius: 10px;


    }


    .rightsection {
      border: 1px solid black;
    }


    .forgot-passs {
      float: right;
    }

    .new-pplatform {
      text-align: center;
    }

    .clients {
      text-align: center;
      margin-bottom: 50px;
      top: 50PX;      
    }

    .clients-dec {
      text-align: center;
    }


    




    .nlb-alogo {
     
        text-align: center;
        padding: 15px;
        margin-bottom: 40px;
        justify-content: center;;
      }
     
      
      


    .nlb-alogo-main-titel {
      margin: 5px;
      padding: 15px;
    }

    .NBL-dec {
      text-align: center;
      justify-content: center;
      border-radius: 15px;
      border: 1px solid #020114;
    }


    .NBL-dec img {
      border-radius: 360%;
    }

    

    .numbers {
      font-weight: 700;
      font-size: 60px;
    }

    .nlb-alogo-main-titel {
      margin: 40px;
    }

    .overview {
      text-align: center;
      margin-top: 125px;

      /* margin-bottom: 25px;
      padding: 50px;
      margin-left: 450px;
      margin-right: 450px;
      justify-content: center; */
    }

    .overview-heading {

      text-align: center;

    }





    .Services-info {
      text-align: center;
      padding: 50px;
      margin-bottom: 15px;
      border-radius: 15px;

    
      
     
    }


    .Services-info-text {
      text-align: justify;
      font-size: 18px;
      

     

    }

    .pagefooter {
      background-image: url("../images/bg_footer.png");
      padding: 35px;
    }

    .footer-heading {
      color: white;
    }

    .para-aboutus {
      color: var(--text-dark-gray);
    }



    ul li {
      text-decoration: none;
    }

    ul li {

      list-style: none;
    }

    .morelinktow {
      color: blue;
    }

    .morelinktow:hover {
      color: #5757defc;
    }


    .para-disclaimer {
      color: white;
    }

    .morelinkbottom {
      color: white;
    }

    ul li a {
      color: var(--text-dark-gray);
    }

    button a {
      color: white;
    }

    /* ul li a:hover{
      color: white;
    } */

    .dashbordcontent {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border: 1px solid darkgray;
      border-radius: 15px;
      padding: 25px;
      margin-top: 15px;
      margin-right: 5%;
      margin-left: 5%;
      height: 100%;
   


    }

    .register {
      /* justify-content: center;
      display: flex; */
      padding: 35px;
      text-align: left;
      border: 1px solid darkgray;
      border-radius: 15px;
      background-color: #c4d0de ;
    }


    .registercontainner {
      /* border: 3px solid rgb(146, 142, 142); */
      padding: 25px;
      margin: 15px;
      border-radius: 15px;
      /* background-color: #e9ebef; */
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  
    }




    .expdate {
      padding-top: 10px;
      height: 100%;
      width: 100%;
      margin-left: 25px;
      margin-bottom: 25px;
      box-shadow: 1px 1px 4px 4px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 5px;


    }

    .Rightdiv {
      text-align: center;
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }

    .rightdivhead {
      border: 1px solid black;
      padding: 5px;
      background-color: black;
      color: white !important;
      align-items: center;
      text-align: center;
    }

    .Whatsnew {
      padding: 10px;
      text-align: left;
      width: 100%;
      height: 300px;
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 2px;
      height: 50%;
      width: 100%;
      overflow: scroll;


    }

    .ActivatedFyers {
      padding: 10px;
      text-align: left;
      width: 100%;
      height: 300px;
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 2px;
      height: 50%;
      width: 100%;
    }

    .whatnewlist {
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      border-radius: 2px;
      border: 1px solid darkgray;

    }


    .table-responsive {

      padding: 10px;
    }


    .LoginHistory {
      margin-top: 10px;
      padding: 10px;
      padding-top: 10px;
      text-align: left;
      width: 100%;
      height: 50%;
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 2px;
    }

    .Sfupdate {
      padding-top: 10px;
      width: 100%;
      height: 100px;
    }

    .dashinfo {
      justify-items: center;
      padding-left: 15px;
    }

    .dashicon {
      text-align: center;
    }

    .WorkingAPI {
      padding-top: 15px;
      text-align: center;
      width: 100%;
      height: 530px;
      padding-top: 10px;
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 2px;
      height: 100%;
      width: 100%;
    }

    .subcription {
      padding: 5px;
    }

    .subcription-section {
      text-align: left;
      box-shadow: 1px 1px 1px 1px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      padding: 25px;
    }

    .subcription-section h6 {
      color: lightslategrey;
    }

    .subcription-item {
      padding: 10px;
      margin: 5px;
      border-radius: 10px;
      box-shadow: 1px 1px 1px 1px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);

    }

    .suboffer {
      margin-left: 15px;
    }

    .heading {
      text-align: center;
    }

    .Statargelistsection {
      box-shadow: 1px 1px 1px 1px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      justify-content: left;
      margin-right: 0;


    }

    .Statargelist {

      border: 1px solid ba;
      padding: 5px;
      margin: 7px;
      color: black;
      border-radius: 5px;
      text-align: left;
      box-shadow: 1px 1px 1px 1px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
    }

    .Buyplan {
      margin: 20px;
      padding: 15pxx;

      box-shadow: 1px 1px 1px 1px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
    }

    .checkeoutsection {

      padding: 25px;
      border: 1px solid rebeccapurple;
      border-radius: 2%;
      margin-top: 10px;
    }

    .invoicediv {
      text-align: center;
      color: rgb(105, 6, 6);
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid black;
    }

    .Cardheder {
      text-align: left;
      border-bottom: 2px solid lightcoral;
      padding: 5px;
    }

    .invoicehead {
      color: rgb(105, 6, 6);

    }

    .actiondiv {
      margin: 15px;
      border: 1px solid black;
      padding: 15px;
      margin-top: 25px;
    }

    .Api-activation {
      justify-content: center;
      display: flex;
      text-align: left;
      padding: 15px;
    }


    .ant-input ant-input-lg {
      border: 1px solid red !important;
    }

    .statargeyallocation {

      padding: 15px;
      margin: 15px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      
    }

    .divone {
      border: 1px solid red;
    }

    tbody,
    td {
      width: 100px;
    }

    .forgot-passs a {
      text-decoration: none;
    }

    .new-pplatform a {
      text-decoration: none;
    }

    .creaccount a {
      text-decoration: none;
    }

    button a {
      text-decoration: none;
    }

    .Forgotpasswordd {
      width: 100%;
      margin-top: 100px;
      text-align: left;
      box-shadow: 0px 0px 5px 5px #9a9898;
      padding: 25px;
      border-radius: 10px;
      border: 1px solid #9a9898;
      background-color: var(--bg-light-white);;
    
   
     border-radius: 15px;
    }

    .Forgotpassword {
      margin-top: 25px;
      text-align: left;
      padding: 25px;
      border-radius: 10px;
      background-color: #c4d0de;
    }

    .accountddetails {
      border: 1px solid black;
    }

    .workingAPIpartners {
      box-shadow: 1px 1px 0px 0px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      border-radius: 5px;
      margin: 10px;
      height: 100%;
      width: 100%;
    }

    .nav-pills .nav-link {
      border-radius: 0;
      color: black;
      background-color: #dddddd;

    }

    .Useredit {
      box-shadow: 1px 1px 2px 2px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      border-radius: 5px;
      padding: 25px;
      margin: 25px;
      justify-content: center;
      text-align: left;
    }

    .Usereditheader {
      border-bottom: 1px solid lightgrey;
      padding-bottom: 5px;
    }

    .UserPhoto {
      width: 100%;
      height: 150px;


    }

    .usersocial {
      box-shadow: 2px 3px 2px 3px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      border-radius: 5px;
      margin-top: 25px;
      padding: 25px;
      text-align: left;
    }

    .usercompaany {

      box-shadow: 2px 3px 2px 3px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      border-radius: 5px;
      margin-top: 25px;
      padding: 25px;
      text-align: left;

    }

    .mangement {
      box-shadow: 0px 0px 1px 1px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 5px;
      margin-top: 25px;
      margin-left: 15px;
      margin-right: 15px;
      padding: 25px;
      text-align: left;
    }

    .DetailPart {
      box-shadow: 0px 0px 1px 1px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 5px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .paper-Trading {
      box-shadow: 0px 0px 1px 1px #cfcdcd;
      border: 1px solid darkgray;
      border-radius: 5px;
      margin-top: 25px;
      padding: 25px;
      text-align: left;

    }

    .trading {
      border: 1px solid rgb(118, 116, 116);
      margin: 15px;
    }


    .Invoice {
      /* box-shadow: 0px 0px 5px 5px #cfcdcd; */
      border: 1px solid rgb(228, 223, 223);
      margin: 5px;
      padding: 15px;
    }



    .autha {
      border: 1px solid black;
    }

    .form-check {

      height: 50px;
      padding: 15px;
      margin-left: 5px;
    }

    .radio-section {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;

    }

    .radio-item [type="radio"] {
      display: none;
    }

    .radio-item+.radio-item {
      margin-top: 15px;
    }

    .radio-item label {
      display: block;
      padding: 20px 60px;
      background: whitesmoke;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 6px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 400;
      min-width: 250px;
      white-space: nowrap;
      position: relative;
    }

    .radio-item label:after,
    .radio-item label:before {
      content: "";
      position: absolute;
      border-radius: 50%;
    }

    .radio-item label:after {
      height: 20px;
      width: 20px;
      border: 2px solid #524eee;
      left: 20px;
      top: calc(50% - 12px);
    }

    .radio-item label:before {
      background: #524eee;
      height: 10px;
      width: 10px;
      left: 27px;
      top: calc(50% - 5px);
      transform: scale(5);
      transition: .4s ease-in-out 0s;
      opacity: 0;
      visibility: hidden;
    }

    .radio-item [type="radio"]:checked~label {
      box-shadow: 0px 0px 5px 5px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
    }


    .radio-item [type="radio"]:checked~label:before {
      opacity: 1;
      visibility: visible;
      transform: scale(3);
    }

    .statragycart {
      box-shadow: 0px 0px 5px 5px #cfcdcd;
      border: 1px solid rgb(228, 223, 223);
      margin-top: 25px;
      padding: 25px;
      background-color: #3f51b5cc;

    }

    .card {
      margin-bottom: 15px;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      border: 1px solid rgb(228, 223, 223);
      background-color: #090b3c;

    }


    .card-body input[type="checkbox"]:checked~label {
      border: 5px solid white;

    }

    .billdetial {
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      padding: 15px;
      margin-bottom: 25px;
    }

    .Stlist {
      margin: 5px;
      height: 500px;
      border-radius: 15px;
      border: 2px solid gray;
      box-shadow: 0px 0px 5px 5px #cfcdcd;
    }

    .radio-item {
      margin-bottom: 25px;

    }

    .tophead {
      height: 75px;
      border-radius: 0px 0px 0px 0px;
      background-color: #A0A0A0;
      margin-bottom: 15px;
    }

    .wallatelog th {
      width: 125px;
    }

    .Wallateheder,
    h2 {
      text-align: left;

    }

    .modal-body {
      text-align: left;
    }

    .card-body label {
      cursor: pointer;
    }


    .card-body label input[type="checkbox"] {
      display: none;
    }


    .card-body label span {
      position: relative;
      display: inline-block;
      background: grey;
      padding: 15px 30px;
      border-radius: 15px;
      color: white;
    }

    .card-body {
      background-color: var(--bg-features-3);
    }

    .card-body label span:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 50%;
      background: rgba(255, 255, 255, .1);

    }


    .card-body label input[type="checkbox"]:checked~span {
      background: black;
      color: white;
    }




    .membercard {
      border: 1px solid gray;
      padding: 15px;
      border-radius: 15px;



    }



    .membercardhed h1 {
      color: white;
      font-weight: bold;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      background-color: #617dfb;
      padding: 5px;
      border-radius: 10px;

    }

    .membercardhed {

      background: #617dfb;
      border-radius: 70px;


    }

    .carhedamount {
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

    }

    .serchbox {
      height: 100%;
      padding: 15px;
      margin: 15px;
      border: 1px solid gray;
      border-radius: 5px;
    }

    .clientlist input.formcontrol {
      border-color: red;
    }

    .clientlist {
      height: 100%;
      padding: 15px;
      margin: 15px;
      border: 1px solid gray;
      border-radius: 5px;

    }

    .filterhead {
      color: #524eee;
    }

    .a {
      color: #ff5d2a;
      font-weight: bold;
    }

    .membershipdiv {
      display: flex;
      align-items: center;
      justify-content: center;

    }

    /* box part */
    .box {
      position: relative;
      height: 430px;
      width: 250px;
      text-align: center;
      border-radius: 15px;
      background: #F8F9F9;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0.1px 4px 20px 2px rgba(0, 0, 0, 0.1);
      border: 3px solid lightgray;


    }

    .box:nth-child(2) {
      margin: 0 100px;
    }

    /* top part */
    .top {
      margin-top: 20px;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      height: 200px;
      border-radius: 15px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%, 0% 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;

    }

    .top i {
      font-size: 25px;
      margin-right: 5px;
    }

    .top p {
      font-weight: bold;
      letter-spacing: 2px;
      color: #fff;
      text-transform: uppercase;
      font-size: 25px;
    }

    .one .top {
      background-image: linear-gradient(to left, #F44336, #E91E63);
    }

    .one button {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      width: 130px;
      height: 50px;
      border-radius: 20px;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      text-transform: capitalize;
      outline: none;
      letter-spacing: 1.1px;
      z-index: 25;
      transition: 0.4s;
      box-shadow: 0.1px 4px 15px 2px rgba(0, 0, 0, 0.1);
    }

    button .fa-shopping-cart {
      font-size: 18px;
      margin-left: 10px;
    }

    .one button {
      background-image: linear-gradient(to left, #F44336, #E91E63);
    }

    /* price part */
    #price {
      position: absolute;
      top: 115px;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      z-index: 25;
      text-align: center;
      line-height: 100px;
      font-weight: bold;
      font-size: 28px;
      background: #e8f7f7;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .one #price {
      color: #F44336;
    }

    #price i {
      margin-right: 5px;
      font-size: 20px;
    }

    .mid {
      height: 200px;
      width: 80%;
      margin-top: 200px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .mid p {
      padding: 7px 0;
      opacity: 0.8;
    }

    .mid p:first-child {
      font-weight: bold;
      font-size: 25px;
      margin-bottom: 15px;
      letter-spacing: 1px;
    }

    .mid i {
      margin-right: 12px;
      color: #AED581;
    }

    .fa-times:before {
      color: #FF7043;
    }

    .invoicedet {
      border: 1px solid black;
      margin-top: 15px;
      padding: 15px;
      border-radius: 5px;


    }



    .renewplan {
      margin-top: 15px;
      padding: 5px;
    }

    .expirdiv {
      border: 1px solid black;
      margin-top: 15px;
      border-radius: 5px;
    }

    .expierdiv {
      border: 1px solid black;
      margin-top: 15px;
      border-radius: 5px;
    }

    .butgrp {
      margin: 15px;
    }

    .editapi {
      padding-bottom: 15px;

    }

    .fyerssucess {
      margin-top: 25px;
    }

    .telegrambtngrp {

      text-align: left;
      margin: 25px;
      padding: 10px;
      border-radius: 5px;

    }

    .btnsubmit {
      border: 0;
      border-radius: 0.25em;
      background: initial;
      background-color: #3085d6;
      color: #fff;
      font-size: 1.0625em;
    }

    .Tlegramtoken {
      text-align: center;
    }

    .teletokenheder {
      text-align: center;
      padding: 15px;

    }

    .teletokenheder p {
      font-size: large;

    }

    .teletokenheder a {
      text-decoration: none;
      font-size: large;
      font-weight: 500;
    }

    .alertpopup {
      text-align: center;
      margin: 25px;
      padding: 15px;
      color: lightgrey;
    }

    .alertmassage {
      border: 1px solid white;
      border-radius: 5px;
      text-align: center;
      padding: 15px;
      color: lightgrey;
    }

    /* .alertmassage:hover {
    border: 1px solid blueviolet;
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    color: blueviolet;
  } */



    .alertchannel {
      border: 1px solid white;
      border-radius: 5px;
      text-align: center;
      padding: 15px;
      color: lightgrey;

    }


    /* .alertchannel:hover {
    border: 1px solid blueviolet;
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    color:blueviolet ;
  } */



    .alertgroup {
      border: 1px solid white;
      border-radius: 5px;
      text-align: center;
      padding: 15px;

    }

    /* .alertgroup:hover {
    border: 1px solid blueviolet;
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    color:blueviolet ;

      
  } */

    input:checked+.inputdiv {
      border: 1px solid blueviolet;
      border-radius: 5px;
      text-align: center;
      color: blueviolet;
      background-color: lightgrey;

    }

    .alertmassage input {
      position: absolute;
      height: 50%;
      width: 50%;
      margin: -125px;
      margin-top: 0px;
      opacity: 0;

    }


    .alertchannel input {
      position: absolute;
      height: 50%;
      width: 50%;
      margin: -125px;
      margin-top: 0px;
      opacity: 0;


    }

    .alertgroup input {
      position: absolute;
      height: 50%;
      width: 50%;
      margin: -125px;
      margin-top: 0px;
      opacity: 0;

    }

    .editable-row .ant-form-item-explain {
      position: absolute;
      top: 100%;
      font-size: 12px;
    }

    .exitfrmall {
      text-align: left;
      margin: 15px;
    }

    .authstatuschne {
      padding: 15px;

    }

    .filterdiv {
      text-align: center;
      padding: 17px;
      margin: 15px;
      border-radius: 5px;
      gap: 25px;
    }


    .tradinglist .ant-table-thead .ant-table-row:nth-child(even) {
      background-color: lightgrey !important;

    }

    .tradinglist .ant-table-thead .ant-table-row:nth-child(odd) {
      background-color: lightgray;
    }


    /* .tradinglist .ant-table-tbody > .tradinglist tr.ant-table-row:hover > td,
  .tradinglist .ant-table-tbody > tr > .tradinglist td.ant-table-cell-row-hover {
    background-color:green;
  } */

   .tradinglist {
    border: 1px solid rgb(143, 139, 139);
    padding: 15px;
    margin: 15px;
    border-radius: 15px;
   }

   .btndiv {
    margin: 5px;
    padding: 5px;
   }

    .ant-table-cell {
      background-color: #c2c5c5 !important;
      color: black !important;

    }

    .ant-table-content {
      background-color: #c1c9c9 !important;
    }

    .ant-table-tbody {
      background-color: #c1c9c9 !important;
    }

    .hookdata {
      padding: 25px;

    }

    .tradelog {
      padding: 15px;

    }

    .tradeloghead {
      color: white;
      padding: 15px;
      background-color: #6876d5;
    }

    .tradinglog {
      border: 1px solid lightcoral;
    }

    .hookdatadiv {
      border: 1px solid lightcoral;
    }

    .billdicripation {
      /* border: 1px solid black; */
      margin-top: 25px;
      text-align: left;
      color: #617dfb;
      padding: 15px
    }

    .chartdiv {
      margin: 150px;
      border: 1px solid black;
      padding: 25px;
    }

    .profilediv {
      border: 1px solid black;
      margin: 15px;
      padding: 25px;
      text-align: left;
      border-radius: 5px;

    }

    .memberinformation {
      margin: 15px;
      padding: 25px;
      border: 1px solid gray;
      border-radius: 15px;

    }

    .Memallinfo {
      margin: 15px;
      padding: 25px;
    }

    .trading {
      padding: 15px;
    }

    .ant-table-bordered {
      border: 1px solid #e8e8e8;
    }

    .orderdiv {



    }

    .positiondiv {

    }

    .pdfinvoice {
      margin: 25px;
    }

    .invoicep {
      height: 100%;
      width: 100%;
      border: 1px solid black;
      margin-top: 100px;
    }

    .inv {
      border: 1px solid black;
    }

    .Companyhead {
      padding: 15px;
    }

    .invoicehead {
      padding: 15px;
    }


    .remaindays {
      padding: 10px;
      border: 1px solid white;
      margin: 15px;
      border-radius: 5px;
    }

    .Apistatus {
      border: 1px solid black;

    }

    .topdiv {
      padding: 15px;
      border-radius: 5px;
      margin: 25px;
    }

    .remaindays {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .Tradingpost {
      border: 1px solid black;
      padding: 15px;
      width: 20%;
      margin-left: 15px;
      margin-top: 15px;
    }

    .ant-table-thead {
      background-color: #0e9d80 !important;
    }


    .remaindays p {
      color: #0e9d80;
      font-size: sa;
    }

    .remaindays h3 {
      font-size: x-large;
      font-weight: bolder;
      color: #F44336;
    }

    .ant-progress-text {
      font-size: small;
      color: rgb(9, 9, 9);
    }


    .poweronoff {
      justify-content: center;
      display: flex;
      align-items: center;
      width: 90px;
      height: 90px;
      margin-left: 100px;
    }



    .circle {
      padding: 10px;
      box-shadow: 0 0 10px rgb(0, 0, 0, .20);
      border-radius: 50%;
      margin-bottom: 100px;
    }

    .circle input {
      appearance: none;
      width: 80px;
      height: 80px;
      border: 15px solid rgb(148, 145, 145);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition-duration: 300ms;
    }

    .circle input::before {
      position: absolute;
      content: '';
      width: 75px;
      height: 50px;
      background-color:transparent;
      top: 75px;
    }

    .circle input::after {
      position: absolute;
      content: '';
      width: 18px;
      height: 65px;
      background-color: rgb(148, 145, 145);
      border-radius: 20px;
      top: 30px;
    }

    .circle input:checked {
      border: 15px solid green;
    }

    .circle input:checked::after {
      background-color: green;
    }

    .ant-table-thead>tr>th {
      background-color: #336699;
      /* Set the background color to a shade of blue */
      color: #ffffff;
      /* Set the text color to white */
    }


    .posttradingdata {
      border: 1px solid rgb(117, 116, 116);
      padding: 15px;
      margin: 15px;
      border-radius: 15px;
    }


    .refreshdata {
      text-align: right;
      color: white;
      padding: 15px;
      background-color: #6876d5;
    }

    .authstatuschne {
      border: 1px solid rgb(131, 128, 128);
      padding: 15px;
      margin: 15px;
      border-radius: 15px;
    }

    .Createapi {
      border: 10px solid rgb(101, 97, 97);
      padding: 15px;
      background-color: white;
      border-radius: 15px;
    }

    .Editapi {
      border: 10px solid rgb(101, 97, 97);
      padding: 15px;
      background-color: white;
      border-radius: 15px;
    }

    .activationdiv {
      padding: 15px;
      margin: 15px;
      border-radius: 15px;
    }


    /* .userloges {
      border: 1px solid gray;
      padding: 15px;
    } */

    .divworkingapi{
      display: flex;
      justify-content: space-between;
    }

    .scroller {
      max-width: 1500px;
    }
    
    .scroller__inner {
      padding-block: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
    
    .scroller[data-animated="true"] {
      overflow: hidden;
      -webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
      );
      mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    }
    
    .scroller[data-animated="true"] .scroller__inner {
      width: max-content;
      flex-wrap: nowrap;
      animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
    }
    
    .scroller[data-direction="right"] {
      --_animation-direction: reverse;
    }
    
    .scroller[data-direction="left"] {
      --_animation-direction: forwards;
    }
    
    .scroller[data-speed="fast"] {
      --_animation-duration: 20s;
    }
    
    .scroller[data-speed="slow"] {
      --_animation-duration: 60s;
    }
    
    @keyframes scroll {
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }
    
    /* general styles */
    
    :root {
      --clr-neutral-100: hsl(0, 0%, 100%);
      --clr-primary-100: hsl(205, 15%, 58%);
      --clr-primary-400: hsl(215, 25%, 27%);
      --clr-primary-800: hsl(217, 33%, 17%);
      --clr-primary-900: hsl(218, 33%, 9%);
    }
    

    
    /* body {
      display: grid;
      min-block-size: 500vh;
      place-content: center;
      font-family: system-ui;
      font-size: 1.125rem;
      background-color: var(--clr-primary-800);
    } */
    
    .tag-list {
      margin: 0;
      padding-inline: 0;
      list-style: none;
    }
    
    .tag-list li {
      padding: 1rem;
      background: var(--clr-primary-400);
      border-radius: 0.5rem;
      box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
    }
    
    /* for testing purposed to ensure the animation lined up correctly */
    .test {
      background: red !important;
    }


    .bg-lightbackgroud {

      background-color:rgb(227, 237, 244,0.);
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    }


    /* ConfirmToast.css */
.confirm-toast {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirm-toast p {
  margin: 0 0 16px 0;
  font-size: 16px;
  color: #333;
}

.confirm-toast button {
  margin: 4px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-toast button.yes {
  background-color: #28a745;
}

.confirm-toast button.no {
  background-color: #dc3545;
}

.select-left-align .ant-select-selection-item {
  text-align: left;
}

.select-right-align .ant-form-item-control {
  display: flex;
  justify-content: flex-end;
}

.statageytopoption{
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  margin: 15px;
  background-color: rgb(167, 214, 244);
  padding: 15px;
  border: 1px solid black;
 
}

.stgallogation{
  /* border: 1px solid black; */
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ant-select-single.ant-select-lg .ant-select-selector {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
  border-radius: 0px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.ant-select-single.ant-select-lg:hover .ant-select-selector {
    border-color:lightgray !important;
}

.placeorderbtn{
  background-color: #7a66ef !important;
  color: white;
  border: none;
  border-radius: 15px;
  height: 50px;
  font-size: 18px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



.nav-tab {
  background-color: #f8f9fa;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-tab ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-tab ul li {
  margin: 0 10px;
}

.nav-tab ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.nav-tab ul li a:hover {
  color: #007bff;
}

.ant-avatar {
  margin-right: 8px;
}


.custom-gap {
    margin-right: 105px; /* Adjust as needed */
}

.dshbordimgdiv  img{
  border: 3px solid white;
  padding: 8px;
  border-radius: 10px;
  background-color: #c86b85;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}


.adminli .navbar-nav li 
  {
    display: inline-block;
    padding: 25px;
}


.fyersacard .card-body{
  background: rgb(243, 67, 8);
  box-shadow: rgba(241, 161, 161, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: flex;
  justify-content: space-around;
}

.fyersacard .ant-btn-primary {
  color: #0b0b0b;
  background-color: white;
  height: 30px;
  width: 100px;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 12px;;
}

.leftitems{
  width: 350px;
  justify-content: space-around;
}




.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "" ;
  border-top: .0em solid !important;
  border-right: .0em solid transparent !important;
  border-bottom: 0;
  border-left: .0em solid transparent !important;
  visibility: hidden !important;
}


.allapis .ant-table-cell {
  background-color: white !important;
  color: black !important;
}


.allapis .ant-table-thead>tr>th {
  background-color:  white;
  color: #ffffff;
}

.notificationPopup {
  position: absolute;
  top: 50px; /* Adjust based on where you want to position it */
  right: 20px; /* Adjust based on where you want to position it */
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 300px;
  height: 500px;
  z-index: 1000;
  overflow: scroll;
}

.messagesList {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.messageItem {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.messageItem:last-child {
  border-bottom: none;
}


.ant-table-thead>tr>th {
  background-color: #336699 !important;
  color: #ffffff !important;
}

.ant-table-cell {
  background-color: rgb(246, 241, 241) !important;
  color: black !important;
}

.ant-btn-primary {
  background-color:#a0c0ff !important;
  font-weight: bold;
  font-size: 15px;
  color: black  ;
}

 body.dark .ant-btn-primary {
  background: #042e82 !important;
  color: white !important;
 }

.transparent-primary-btn {
  background-color: transparent !important; /* Make background transparent */
  border: none !important; /* Remove border */
  color: #1890ff !important; /* Primary color for text */
}


/* Directly target the SVG within the button */
.editplan svg {
  color: rgb(183, 39, 240) !important; /* Apply color to the SVG */
  font-size: 28px !important; /* Increase the font size */
}

/* Target the SVG inside the disabled button */
.editplan:disabled svg {
  color: rgb(171, 171, 171) !important; /* Apply color for disabled state */
  font-size: 28px !important; /* Keep the same font size */
}




.confirm-toast {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #7ae8dd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.card {
  border-radius: 10px; /* Rounded corners for a soft look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  background-color: #fff; /* Clean white background */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
  padding: 20px; /* Adding some padding for a more spacious feel */
}

.card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.card-body {
  text-align: center; /* Center align text for a balanced look */
}

.card h5 {
  font-size: 1.2rem; /* Slightly larger headings */
  font-weight: bold; /* Bold headings */
  color: black; /* Darker color for contrast */
  margin-bottom: 10px; /* Space between elements */
}



.card p, .card h6 {
  font-size: 1rem; /* Uniform font size for consistency */
  color: #666; /* Subtle text color */
  margin-bottom: 5px; /* Space between elements */
}

.Discountamount {
  color: #ff5722; /* Highlight discount in a noticeable color */
  font-weight: bold;
  margin-bottom: 10px;
}

label {
  display: flex; /* Align checkbox and text horizontally */
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

label input[type="checkbox"] {
  margin-right: 8px; /* Space between checkbox and label text */
  transform: scale(1.2); /* Slightly larger checkbox for better usability */
}

label span {
  font-size: 1rem;
  color: #007bff; /* Link-style color for the "Select" text */
  cursor: pointer; /* Pointer cursor to indicate interactivity */
}

label span:hover {
  text-decoration: underline; /* Underline on hover to indicate action */
}



.billdetial {
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9; /* Light background for contrast */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.billdetial h5 {
    font-size: 1.5rem; /* Larger font for the heading */
    font-weight: bold;
    color: #333; /* Darker color for contrast */
    margin-bottom: 20px;
    border-bottom: 2px solid #007bff; /* Accent border under the heading */
    padding-bottom: 10px;
}

.billdetial .table {
    margin-bottom: 0; /* Remove default margin */
    border-collapse: separate; /* Ensure borders don’t collapse */
    border-spacing: 0 10px; /* Add spacing between rows */
}

.billdetial .table th, .table td {
    vertical-align: middle; /* Vertically center text */
    padding: 12px; /* Add padding for better spacing */
}

.billdetial .table th {
    background-color: #0879f3; /* Primary color for table headers */
    color: white; /* White text for contrast */
    border: none; /* Remove default borders */
}

.billdetial .table td {
  background-color:#7fb8f4; /* Primary color for table headers */
  color: white; /* White text for contrast */
  border: none; /* Remove default borders */
}

.billdetial .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f1f1f1; /* Subtle stripe effect */
}

.billdetial .table-success tbody tr {
    background-color: #e9ffe9; /* Light green for success theme */
}

.billdetial .table-striped tbody tr:hover {
    background-color: #e3f2fd; /* Highlight row on hover */
    cursor: pointer; /* Indicate clickable rows */
}

.billdetial .table tfoot th, .table tfoot td {
    font-size: 1.2rem; /* Larger font size for total row */
    font-weight: bold;
    color: #333;
    background-color: #f9f9f9; /* Match background to overall design */
    
}

.billdetial .table tfoot td {
    text-align: right; /* Align total amount to the right */
}


.billdicripation {
  padding: 20px;
  background: #f8f9fa; /* Light grey background for contrast */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05); /* Soft shadow above */
  border-radius: 0 0 12px 12px; /* Rounded bottom corners */
  text-align: right; /* Align text to the right */
}

.billdicripation h5 {
  font-size: 1.25rem; /* Larger font for readability */
  font-weight: 600;
  color: #333; /* Darker color for better contrast */
  margin: 10px 0; /* Spacing between lines */
  letter-spacing: 0.3px; /* Slight letter spacing for modern look */
}

.billdicripation h5 span {
  font-weight: 700; /* Make the total amounts bolder */
  color: #1b1c1d; /* Highlight the amounts with accent color */
}

.billdicripation h6 {
  border-bottom: 2px dotted blueviolet ;
  padding-bottom: 10px;
  color: #020114;
}






.container {
  max-width: 1000px;
}

.checkeoutsection {
  background-color: #f9f9f9;
  border-radius: 8px;
}

.Cardheder {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.invoicehead {
  background-color: #e9ecef;
  padding: 15px;
  border-radius: 8px;
}

.checkeoutsection .table  {
  margin-top: 20px;
}

.btn {
  font-size: 16px;
  padding: 10px 20px;
}

.checkeoutsection h5 {
  font-weight: bold;
}




     
.modern-footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 40px 0;
  font-family: 'Poppins', sans-serif;
}

.footer-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-text {
  font-size: 14px;
  line-height: 1.6;
}

.footer-links {
  list-style-type: none;
  padding-left: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #f0f0f0;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #f39c12;
}

.footer-divider {
  margin: 20px 0;
  border-color: #333;
}

.footer-disclaimer {
  font-size: 12px;
  color: #bbb;
  text-align: center;
}

.footer-links i {
  margin-right: 10px;
  color: #f39c12;
}


.telergaalert {

  margin: 10px;
}


 .ant-table {
  background-color: #dd2424 !important /* Your desired background color */
}

.custom-table .ant-table-thead > tr > th {
  background-color: #dd2424; /* Header background color */
}

.custom-table .ant-table-tbody > tr > td {
  background-color: #dd2424; /* Row background color */
}


.fyerssucess {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;    /* Horizontally center items */
  justify-content: center; /* Vertically center items */
  height: 100vh;           /* Full viewport height */
  text-align: center;      /* Center text */
}



.invoice-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  background: #fff;
  font-family: Arial, sans-serif;
}
.invoice-header h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.invoice-header h4, .invoice-header p {
  margin: 0;
  padding: 0;
}
.invoice-details {
  margin: 20px 0;
}
.invoice-details h5 {
  font-weight: bold;
  margin-bottom: 10px;
}
.invoice-summary table {
  width: 100%;
  margin-top: 20px;
}
.invoice-summary .btn {
  margin-top: 20px;
}

/* Hide elements in PDF mode */
.pdf-mode .no-print {
  display: none;
}


.otp-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: #28a745;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
}


.otp-box {
  width: 40px;
  height: 0px;
  font-size: 22px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}









       

